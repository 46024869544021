import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import {
	AbstractButton,
	Footer as FooterWrap,
	Globe,
	LinkColumn,
	Pill,
	SimpleLink,
} from 'ui'
import { CompanyData } from 'ui/molecules/Navigation/Navigation.types'
import SocialIcons from 'ui/molecules/SocialIcons'
import useLanguageSwitch from '@hooks/useLanguageSwitch'
import { isEnglish } from '@utils/locale'
import { CountryCode, Language, lookups } from '@constants/language'

export interface FooterProps {
	mainLinks?: Array<LinkColumn>
	bottomLinks?: Array<SimpleLink>
	companyData: CompanyData
}

const Footer: FC<FooterProps> = ({ mainLinks, bottomLinks, companyData }) => {
	const icons = companyData.social_icons[0].icons
	const { onLanguageChange, domainLanguage, locale } = useLanguageSwitch()

	const { t } = useTranslation()

	const prepareContactUsPart = () => {
		const { company_name, street, region, zip_code_city } = companyData

		return [company_name, street, region, zip_code_city].filter(
			Boolean
		) as Array<string>
	}

	const { countryCode } = lookups({ language: locale as Language })

	const isXk = countryCode === CountryCode.Kosovo

	return (
		<FooterWrap
			mainLinks={mainLinks}
			bottomLinks={bottomLinks}
			contactUs={prepareContactUsPart()}
			linkComponent={AbstractButton}
		>
			<div className="flex w-full flex-col gap-y-4">
				<SocialIcons
					icons={icons}
					theme="light"
					className="sm:justify-center md:justify-end lg:justify-end"
				/>
				<div className="flex justify-end sm:justify-center">
					<AbstractButton
						title={`Phone: ${companyData.phone_number}`}
						href={`tel:${companyData.phone_number}`}
						className="p_highlight text-white"
					>
						{t('Call us')}
					</AbstractButton>
					<pre className="p_highlight text-[#7D050029]"> / </pre>
					<AbstractButton
						title={`E-mail: ${companyData.email}`}
						href={`mailto:${companyData.email}`}
						className="p_highlight text-white"
					>
						{t('Send an E-mail')}
					</AbstractButton>
				</div>
			</div>
			<div className="mt-auto flex justify-end self-end text-white sm:mx-auto sm:mt-6 sm:w-fit sm:flex-col sm:justify-center sm:gap-2">
				<AbstractButton
					href="/change-country"
					className="p_highlight flex w-fit flex-row rounded-full border-2 border-[#7D050029] px-5 py-3"
				>
					{t('Change country')} <Globe className="ml-3" />
				</AbstractButton>
				{!isXk && (
					<Pill
						items={[domainLanguage, 'EN']}
						active={isEnglish(locale) ? 'EN' : domainLanguage}
						onClick={onLanguageChange}
						theme="light"
						className="ml-2 sm:mx-auto sm:w-fit"
					/>
				)}
			</div>
		</FooterWrap>
	)
}

export default Footer
